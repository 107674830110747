<template>
  <v-container fluid>
    <PageNavigation :items="items" />
    <v-row>
      <v-col cols="12">
        <base-material-card
          :title="$vuetify.lang.t('$vuetify.companyInfo')"
          icon="mdi-account"
        >
          <v-row>
            <v-col cols="12" lg="4" md="5" sm="5">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.search')"
                v-model="search"
                append-icon="mdi-magnify"
              />
            </v-col>

            <v-spacer />

            <v-col
              cols="12"
              lg="2"
              md="2"
              sm="3"
              class="pa-0 d-flex justify-end align-center"
            >
              <v-btn
                v-if="permissionCheck('add')"
                color="green"
                elevation="2"
                @click="handleDialog"
              >
                <v-icon left>mdi-account-plus</v-icon>
                {{ $vuetify.lang.t("$vuetify.add") }}
              </v-btn>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <GeneralTable
                :head="headers"
                :data="datas"
                :search="search"
                :dialog.sync="dialog"
                v-on:edititem="handleDialogEdit"
                v-on:deleteitem="handleDeleteDialog"
              />
            </v-col>
          </v-row>

          <InfoCRUD
            :dialog.sync="dialog"
            :myObj="updataData"
            @handleData="handleEditConfirm"
            @handleCancel="hanldeCancel"
          />

          <DialogDelete
            :dialogDelete.sync="dialogDelete"
            :deleteObj="updataData"
            @handleDelete="handleDeleteConfirm"
          />
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { sellDelete, upsertSell, sellList } from "@/api/basicdata";
import { profilePicUpload } from "@/api/company";

const newUpdata = () => {
  return {
    id: "",
    company: "",
    website: "",
    registration_no: "",
    tax_no: "",
    expire_date: "",
    phone2: "",
    code: "",
    address: "",
    tel: "",
    fax: "",
    contact: "",
    email: "",
    exchange_terms: "",
    note: "",
    attach: [],
  };
};

export default {
  name: "CustomersInfo",
  components: {
    GeneralTable: () => import("@/components/tableImg"),
    InfoCRUD: () => import("@/components/customers/infoCRUD"),
    DialogDelete: () => import("@/components/deleteDialog"),
    PageNavigation: () => import("@/components/pageNavigation"),
  },
  data() {
    return {
      dialog: false,
      wait: false,
      dialogDelete: false,
      search: "",
      datas: [],
      updataData: newUpdata(),
      headers: [
        {
          text: this.$vuetify.lang.t("$vuetify.itemNo"),
          value: "itemNo",
          sortable: false,
          align: "center",
          width: "80px",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.companyName"),
          value: "company",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.contactPerson"),
          value: "contact",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.addr"),
          value: "address",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.phone"),
          value: "tel",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.email"),
          value: "email",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.note"),
          value: "note",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.createTime"),
          value: "gen_time",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.opt"),
          value: "actions",
          sortable: false,
          align: "center",
          width: "80px",
        },
      ],
      items: [
        { text: this.$vuetify.lang.t("$vuetify.home") },
        { text: this.$vuetify.lang.t("$vuetify.company") },
        { text: this.$vuetify.lang.t("$vuetify.companyInfo") },
      ],
    };
  },
  methods: {
    handleDialog() {
      this.dialog = true;
      this.updataData = newUpdata();
    },
    handleDialogEdit(obj) {
      if (this.permissionCheck("modify")) {
        this.dialog = true;
        this.updataData = obj;
      }
    },
    handleEditConfirm(obj, files) {
      this.dialog = false;

      const arr = [];

      obj.attach.length > 0 ? obj.attach.forEach((p) => arr.push(p.md5)) : "";

      if (files != undefined) {
        const fileForm = new FormData();
        fileForm.append("file", files);

        profilePicUpload(fileForm)
          .then((res) => {
            arr.push(res.data.md5);

            obj.attach = JSON.stringify(arr);
            this.updataData = obj;

            this.wait = true;
          })
          .catch((err) => console.log("File Upload Error", err));
      } else {
        obj.attach = JSON.stringify(arr);
        this.updataData = obj;
        this.wait = true;
      }
    },
    handleDeleteDialog(obj) {
      this.dialogDelete = true;
      this.updataData = obj;
    },
    handleDeleteConfirm(obj) {
      this.dialogDelete = false;

      sellDelete(obj.id)
        .then(() => {
          this.datas.splice(this.datas.indexOf(obj), 1);
        })
        .catch((err) => console.log("Company Delete Error", err));
    },
    hanldeCancel() {
      this.dialog = false;
    },
    getData() {
      sellList()
        .then((data) => {
          this.datas = [...data.items];

          this.datas.forEach((p, key) => (p.itemNo = key + 1));
        })
        .catch((err) => console.log(err));
    },
  },
  mounted() {
    this.getData();
  },
  watch: {
    wait: function () {
      if (this.wait) {
        upsertSell(this.updataData)
          .then(() => {
            this.getData();
            this.wait = false;
          })
          .catch((err) => console.log("new Company Error", err));
      }
    },
  },
};
</script>
