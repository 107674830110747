import request from '@/utils/request'
import store from '@/store'
import axios from 'axios'

export function profilePicUpload(data){
  return axios.post(
    request.defaults.baseURL + '/file/upload',
    data,
    {
      headers: {
        "X-Access-Token": `${ store.getters.token }`,
        "Content-Type": `multipart/form-data; boundary=${ data._boundary }`
      }
    }
  )
}

// 用户列表
export function usersList(data) {
  return request({
    url: '/users/list',
    method: 'post',
    data: data
  })
}

export function usersInfo(id) {
  return request({
    url: '/users/info/' + id,
    method: 'get'
  })
}
// 添加用户
export function usersUpsert(data) {
  return request({
    url: '/users/upsert',
    method: 'post',
    data: data
  })
}

// 删除用户
export function usersDel(uid) {
  return request({
    url: '/users/delete/' + uid,
    method: 'delete'
  })
}

// 部门列表
export function departmentList(data) {
  return request({
    url: '/department/list',
    method: 'get'
  })
}
// 添加部门
export function departmentUpsert(data) {
  return request({
    url: '/department/upsert',
    method: 'post',
    data: data
  })
}

// 删除部门
export function departmentDel(id) {
  return request({
    url: '/department/delete/' + id,
    method: 'delete'
  })
}

// 考勤添加
export function attendanceUpsert(data) {
  return request({
    url: '/attendance/upsert',
    method: 'post',
    data: data
  })
}

// 考勤列表
export function attendanceList(data) {
  return request({
    url: '/attendance/list',
    method: 'post',
    data: data
  })
}

// 考勤统计
export function attendanceStat(data) {
  return request({
    url: '/attendance/stat',
    method: 'post',
    data: data
  })
}

// 重置密码
export function userPasswordReset(data) {
  return request({
    url: '/users/password/reset',
    method: 'post',
    data: data
  })
}
